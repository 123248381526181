import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Loader from "../../../components/Loader/Loader";
import { Header3, Header5, reporaColor } from "../../../components/global/commonStyles";
import {
  Wrapper,
  HeaderWrapper,
  BackButton,
  CloseButton,
  SignupLink,
  CreateContainer,
  LinkedInButton,
  LinkedInButtonImageContainer,
  LinkedInButtonText,
  FormError
} from "./CommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const CompanyLogo = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const InviteText = styled(Header5)`
  color: ${reporaColor.brightSkyBlue};
`;

const Step2Rep = props => {
  const company = props.company;
  const companyName = company && company.name;
  const companyLogo = company && company.logo;
  document.getElementById("contentLoader").scrollIntoView();
  window.scrollTo(0, 0);
  return (
    <section>
      <Wrapper>
        {props.isFetching && <Loader overlayed size="medium" />}

        <BackButton onClick={props.handleBack}><FontAwesomeIcon icon={faArrowLeft} /></BackButton>
        <CloseButton onClick={props.handleClose}><FontAwesomeIcon icon={faTimes} /></CloseButton>

        <HeaderWrapper>
          <Header3 textAlign="center">Rep Log In</Header3>
          <Header5 textAlign="center" textColor={`${reporaColor.warmGrey}`}>Using your LinkedIn account:</Header5>

          {(props.invitationCompanyId || props.invitationId) && (
            <div className="d-flex flex-column align-items-center">
              {
                companyName ?
                  <InviteText textAlign="center" textColor={`${reporaColor.warmGrey}`}>{companyName} has invited you to join to their company!<br />Login to accept the request</InviteText>
                  :
                  <InviteText textAlign="center" textColor={`${reporaColor.warmGrey}`}>A Company has invited you to join them!<br />Login to accept the request</InviteText>
              }
              {companyLogo &&
                <CompanyLogo>
                  <img alt="company_logo" src={companyLogo} />
                </CompanyLogo>
              }
            </div>
          )}
        </HeaderWrapper>

        {props.loginErrorMessage && <FormError>{this.props.loginErrorMessage}</FormError>}

        <LinkedInButton onClick={() => props.handleGetSocialUrl("LINKEDIN")}>
          <LinkedInButtonImageContainer><img src="/assets/global/linked-in.svg" alt="" /></LinkedInButtonImageContainer>
          <LinkedInButtonText>Log In using LinkedIn</LinkedInButtonText>
        </LinkedInButton>
      </Wrapper>

      <CreateContainer>
        <span>Don't have an account? <SignupLink><Link to="/signup">Sign Up</Link></SignupLink></span>
      </CreateContainer>
    </section>
  );
};

export default Step2Rep;
