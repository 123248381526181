import React from "react";
import styled from "styled-components";

import {
  reporaColor,
  ReporaButton,
  FormGroup,
} from "../../../components/global/commonStyles";
import { validateEmail } from "../../../utils";
import Loader from "../../../components/Loader/Loader";

const Wrapper = styled.div`
  max-width: 502px;
  width: 100%;
  border-radius: 2px;
  background: white;
  box-shadow: 0 0 8px 0 ${reporaColor.black02};
  margin: 0 auto;
  margin-top: 60px;
  padding: 35px 55px;
  position: relative;
`;

const Header = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
`;

const SubHeader = styled.p`
  color: ${reporaColor.brightSkyBlue};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-top: 25px;
  margin-bottom: 20px;
`;

const InputError = styled.div`
  font-size: 10px;
  color: ${reporaColor.watermelon};
  position: absolute;
  bottom: -15px;
  left: 0px;
`;

const ErrorMessage = styled.div`
  margin-top: 30px;
  color: ${reporaColor.watermelon};
`;

class SendForgotEmail extends React.Component {
  state = {
    email: "",
    emailError: "",
  };

  componentDidMount() {
    this.props.handleForgotPasswordClear();
  }

  handleInputChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value,
      emailError: "",
    });
  };

  handleSubmitButton = () => {
    const { email } = this.state;

    if (validateEmail(email)) {
      this.setState({
        emailError: "",
      });

      this.props.handleForgotPassword(email);
    } else {
      this.setState({
        emailError: "Should be a valid email address",
      });
    }
  };

  handleNewRequestButton = () => {
    this.props.handleForgotPasswordClear();
  };

  render() {
    const { email, emailError } = this.state;
    const { forgotPasswordError, fetchingForgotPassword, forgotPasswordSent } =
      this.props;

    if (forgotPasswordSent) {
      return (
        <Wrapper>
          <div>
            <Header>Reset Your Password</Header>
            <SubHeader>We have emailed you at {email}</SubHeader>
          </div>
          <div>
            <p>
              If you have not received an email within 5 minutes, you may have
              used a different email address to sign up for RepScore.
            </p>
            <ReporaButton
              white
              className="m-t-30"
              full
              onClick={this.handleNewRequestButton}
            >
              Try Another Email Address
            </ReporaButton>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        {fetchingForgotPassword && <Loader overlayed size="medium" />}
        <div>
          <Header>Reset Your Password</Header>
          <SubHeader>
            We’ll email you a link to create a new password, <br></br> and get
            you back on Repora in no times.
          </SubHeader>
        </div>
        <div>
          <FormGroup marginTop="none">
            <label>Email Address</label>
            <input
              name="email"
              type="email"
              value={email}
              onChange={this.handleInputChange}
            />
            <InputError>{emailError}</InputError>
          </FormGroup>

          {forgotPasswordError && (
            <ErrorMessage>{forgotPasswordError}</ErrorMessage>
          )}

          <ReporaButton
            className="m-t-30"
            full
            onClick={this.handleSubmitButton}
          >
            Submit
          </ReporaButton>
        </div>
      </Wrapper>
    );
  }
}

export default SendForgotEmail;
