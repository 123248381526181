const development = {
  ENVIRONMENT: "development",
  LOCAL_ROOT_URL: "https://dev.repora.co",
  // LOCAL_ROOT_URL: "https://stagingapp.repora.co",
  // LOCAL_ROOT_URL: "https://localhost:8080"
};

const local = {
  ENVIRONMENT: "local",
  // LOCAL_ROOT_URL: "https://reporadev.cypressdigitalmedia.com"
  LOCAL_ROOT_URL: "https://localhost:8080",
};

const staggingLCL = {
  ENVIRONMENT: "staggingLCL",
  LOCAL_ROOT_URL: "https://localhost:8080",
};

const qa = {
  ENVIRONMENT: "qa",
  LOCAL_ROOT_URL: "https://repora.cypressdigitalmedia.com",
};

const stagging = {
  ENVIRONMENT: "stagging",
  // LOCAL_ROOT_URL: "https://reporastaging.cypressdigitalmedia.com"
  // LOCAL_ROOT_URL: "https://staging.repora.co",
  LOCAL_ROOT_URL: "https://stagingapp.repora.co",
};

const prod = {
  ENVIRONMENT: "prod",
  LOCAL_ROOT_URL: "https://repora.co",
};

const test = {
  ENVIRONMENT: "test",
  LOCAL_ROOT_URL: "https://test.repora.co",
};

/* Test keys provided in Google website
   (https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do)
   is used in all envs other than prod.
*/
const reCaptchaKeys = {
  qa: {
    siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    secretKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
  },
  development: {
    siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    secretKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
  },
  local: {
    siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    secretKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
  },
  stagging: {
    siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    secretKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
  },
  staggingLCL: {
    siteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    secretKey: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
  },
  prod: {
    siteKey: "6LfGpxkiAAAAAFMfFUtMFidiP1N6bdKmACSOhchS",
    secretKey: "6LfGpxkiAAAAAAHRheOPTB9TwHxx7-X1H7L9qVpC",
  },
  test: {
    siteKey: "6LfGpxkiAAAAAFMfFUtMFidiP1N6bdKmACSOhchS",
    secretKey: "6LfGpxkiAAAAAAHRheOPTB9TwHxx7-X1H7L9qVpC",
  },
};

const commonAPI = {
  qa: "https://apiqa.cypressdigitalmedia.com",
  development: "https://dev.repora.co",
  // development: "https://stagingapp.repora.co",
  local: "https://dev.repora.co",
  local: "https://stagingapp.repora.co",
  stagging: "https://staging.repora.co",
  stagging: "https://stagingapp.repora.co",
  staggingLCL: "https://reporastaging.cypressdigitalmedia.com",
  prod: "https://repora.co",
  test: "https://test.repora.co",
};

const crmFileUrl = {
  development:
    "https://s3.us-west-2.amazonaws.com/repora-crm-files/1663316898154-Sample-CRM-File.xlsx",
  qa: "https://s3.us-west-2.amazonaws.com/repora-crm-files/1663316898154-Sample-CRM-File.xlsx",
  stagging:
    "https://s3.us-west-2.amazonaws.com/repora-crm-files-staging/1663331841690-Sample-CRM-File.xlsx",
  local:
    "https://s3.us-west-2.amazonaws.com/repora-crm-files/1663316898154-Sample-CRM-File.xlsx",
  staggingLCL:
    "https://s3.us-west-2.amazonaws.com/repora-crm-files-staging/1663331841690-Sample-CRM-File.xlsx",
  prod: "https://s3.us-west-2.amazonaws.com/repora-crm-files-prod/1663315618723-Sample-CRM-File.xlsx",
  test: "https://s3.us-west-2.amazonaws.com/repora-crm-files-test/1663315618723-Sample-CRM-File.xlsx",
};

let common = {
  PASSPORT_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":4031",
  COMPANY_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":4032",
  ROLE_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":3033",
  CREATE_COMPANY_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":3031",
  REP_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":4033",
  CUSTOMER_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":4033",
  NOTIFICATION_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":4034",
  ADMIN_API: commonAPI[process.env.REACT_APP_NODE_ENV] + ":4035",
  API_TIMEOUT_DURATION: 0,
  CRM_FILE_LOCATION: crmFileUrl[process.env.REACT_APP_NODE_ENV],
  CRM_FILE_NAME: "Sample-CRM-File.xlsx",
  RE_CAPTCHA_KEYS: reCaptchaKeys[process.env.REACT_APP_NODE_ENV],
};

const envs = {
  development: development,
  qa: qa,
  stagging: stagging,
  local: local,
  staggingLCL: staggingLCL,
  prod: prod,
  test: test,
};

export default {
  ...common,
  ...envs[process.env.REACT_APP_NODE_ENV],
};

// console.log(commonAPI[process.env.REACT_APP_NODE_ENV]);
