import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import queryString from "query-string";
import { get, isEmpty, isEqual } from "lodash";

import { login, getSocialUrl, socialLogin, clearLoginErrorMessages, clearLoginMessages, getIframeLogin } from "../../actions/authActions";
import { getCompanyPublicInfo , getCompanyInfoByInvite } from "../../actions/companyActions";
import constants from "../../constants";
import View from "./components/View";
import routes from "../../components/PrivateRoutes/routes";
import Disconnected from "./components/Disconnected";
import Spinner from "../../components/Spinner/Spinner";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 100px;

  img {
    width: 100px;
    height: 100px;
  }
`;

class SignUp extends React.Component {
  state = {
    step: 1,
    redirectLink: "",
    userRole: "",
    provider: "",
    errorMessage: "",
    step2Customer: {
      email: "",
      password: "",
    },
    step2Company: {
    },
    invitationCompanyId: null,
    invitationId:'',
    countryList: [],
    cityList: [],
    industriesList: [] ,

    companyKey : "",
    partnerKey : ""
  };

  componentDidMount() {
    const { location } = this.props;
    if(location.pathname === constants.REPORA_SA_PATH.REPLISTING || location.pathname === constants.REPORA_SA_PATH.COMPANYVIEW ){
      const queryParams = queryString.parse(this.props.location.search);
      if(!isEmpty(queryParams)){
        let redirectUrl =  `${constants.REPORA_SA_PATH.COMPANYVIEW}`
        if( queryParams.companyKey && queryParams.partnerKey){
            let obj = { 
              company_key : encodeURIComponent(queryParams.companyKey),
              api_key : encodeURIComponent(queryParams.partnerKey)
             }
            this.props.handleIframeLogin(obj , this.props.history , redirectUrl);
        }
      }else{
        let obj = {username:"sunitha.gadam+17@kanerika.com",password:"Anitha@123",role:"ADMIN"}
        let redirectUrl ;
        if(location.pathname === constants.REPORA_SA_PATH.REPLISTING){
          redirectUrl =  `${constants.REPORA_SA_PATH.REPLISTING}?companyid=43434343&&api_key=lkjl809sadkjfkljlkajsdf`
        }else if(location.pathname === constants.REPORA_SA_PATH.COMPANYVIEW){
          redirectUrl =  `${constants.REPORA_SA_PATH.COMPANYVIEW}`
        }
        this.props.handleLogin(obj.username , obj.password , obj.role , this.props.history , redirectUrl)
      }
    }
    const queryParams = queryString.parse(this.props.location.search);

    if (queryParams && queryParams.code && queryParams.state) {
      const split = queryParams.state.split("_") || [];

      const data = split.map(urlParam => {
        const splited = urlParam.split("-");
        const key = splited.shift();
        const value = splited.join("-");

        return ({
          [key]: value
        });
      });

      let flattened = { code: queryParams.code };
      data.forEach(element => {
        flattened = { ...flattened, ...element }
      });

      const { code, role, provider, invitedCompanyId } = flattened;
      if (code && role && provider) {
        this.props.handleSocialLogin(code, role, provider, this.props.history, invitedCompanyId);
      }
    }

    const queryCompanyId = (queryParams && queryParams.companyId) || null;
    const inviteId = (queryParams && queryParams.inviteId) || null;
    if(inviteId){
      this.props.handleGetCompanyInfoByInviteId(inviteId);

      this.setState({
        userRole: "REP",
        invitationId: inviteId
      }, () => this.handleStepSubmit());
    }
    else if (queryCompanyId) {
      this.props.handleGetCompanyPublicInfo(queryCompanyId);

      this.setState({
        userRole: "REP",
        invitationCompanyId: queryCompanyId
      }, () => this.handleStepSubmit());
    } else {
      // User gets redirected to login page when trying to access private urls without loggedin.
      // If user is redirected from VIEW_REP_CUSTOMER or RATE_REP_CUSTOMER route, then we know that the current user is a Customer and we can 
      // directly show the Customer specific login page instead of main login page.
      try {
        if (this.props.location.state && this.props.location.state.from) {
          const path = this.props.location.state.from.split("/")[1].split("?")[0];
          if (path === routes.VIEW_REP_CUSTOMER.path || path === routes.RATE_REP_CUSTOMER.path) {
            this.setState((prevState) => ({
              ...prevState,
              userRole: constants.USER_ROLES.CUSTOMER,
            }), () => this.handleStepSubmit());
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    try {
      this.props.handleClearLogInMsg();
    } catch (e) {
      console.log(e);
    }
  }

  componentWillReceiveProps(newprops) {
    if (this.state.step < 3) {
      if (newprops.socialDataValid) {
        this.setState({ step: 3 });
      } else if (this.state.userRole !== "") {
        this.setState({ step: 2 });
      }
    }
  }

  componentDidUpdate(prevProps){
    if(!isEmpty(this.props.companyPublicInfo) && !isEqual(this.props.companyPublicInfo,prevProps.companyPublicInfo)){
      window.localStorage.setItem('companyId',this.props.companyPublicInfo?.companyId)
    }
  }

  handleClose = () => {
    this.setState({ redirectLink: "/" })
  };

  handleBack = () => {
    if (this.state.step === 2) {
      this.setState({
        step: 1,
        userRole: "",
        redirectLink: "",
        provider: "",
        errorMessage: "",
        step2Customer: {
          email: "",
          password: "",
        },
        step2Company: {
          companyName: "",
          industry: "",
          country: "",
          city: "",
          state: "",
          zipCode: "",
          phoneNumber: "",
          valid: false
        }
      });
    } else if (this.state.step === 1) { window.history.back() }
  }

  handleRoleSelection = (value) => {
    this.setState({ userRole: value }, () => this.handleStepSubmit());
  }

  handleInputChange = (event) => {
    if (this.state.step === 2) {
      if (this.state.userRole === constants.USER_ROLES.CUSTOMER) {
        let step2CustomerData = this.state.step2Customer;
        step2CustomerData[event.target.getAttribute("name")] = event.target.value;
        this.setState({ step2Customer: step2CustomerData });
      } else if (this.state.userRole === constants.USER_ROLES.COMPANY) {
        let step2CompanyData = this.state.step2Company;
        step2CompanyData[event.target.getAttribute("name")] = event.target.value;
        step2CompanyData["valid"] = this.checkingStepComplete(step2CompanyData);
        this.setState({ step2Company: step2CompanyData });
      }
    }
  }

  handleStepSubmit = () => {
    const { userRole } = this.state;

    if (this.state.step === 1 && userRole !== "") {
      this.setState({ step: 2 });
    }

    if (this.state.step === 2) {
      const redirectPath = get(this.props, "location.state.from", `/${constants.ROLES_REDIRECTION[userRole]}`);

      if (userRole === constants.USER_ROLES.CUSTOMER) {
        this.props.handleLogin(
          this.state.step2Customer.email,
          this.state.step2Customer.password,
          constants.USER_ROLES.CUSTOMER,
          this.props.history,
          redirectPath
        );
      } else if (userRole === constants.USER_ROLES.COMPANY) {
        this.props.handleLogin(
          this.state.step2Company.email,
          this.state.step2Company.password,
          constants.USER_ROLES.COMPANY,
          this.props.history,
          redirectPath
        );
      }
    }
  }

  handleGetSocialUrl = providerName => {
    const { userRole, invitationCompanyId } = this.state;

    let companyId = window.localStorage.getItem('companyId') || "";
    this.props.handleGetSocialUrl(providerName, userRole, "/login", invitationCompanyId || companyId);
  }

  checkingStepComplete = stepData => {
    const stepValues = Object.values(stepData);
    return stepValues.indexOf("") === -1;
  }

  render() {
    if (this.state.redirectLink !== "") {
      return <Redirect push={true} to={this.state.redirectLink} />
    }

    if((this.props.location?.pathname === constants.REPORA_SA_PATH.REPLISTING || this.props.location?.pathname === constants.REPORA_SA_PATH.COMPANYVIEW) && this.props.isFetching){
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }
    // Disconnected

    try {
      if (this.props.loginErrorMessage.length > 0) {
        let splitErrorMsg = this.props.loginErrorMessage.split("from");
        if (splitErrorMsg[0] === "We noticed your Repora account was disconnected ") {
          return (<Disconnected error={this.props.loginErrorMessage}></Disconnected>)
        }
      }
    } catch (e) {

    }

    return (
      <View
        {...this.props}
        {...this.state}
        handleBack={this.handleBack}
        handleClose={this.handleClose}
        handleGetSocialUrl={this.handleGetSocialUrl}
        handleStepSubmit={this.handleStepSubmit}
        handleRoleSelection={this.handleRoleSelection}
        handleInputChange={this.handleInputChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.auth.isFetching,
  loginErrorMessage: state.auth.loginErrorMessage,
  isAuthenticated: state.auth.isAuthenticated,
  companyPublicInfo: state.company.companyPublicInfo
});

const mapDispatchToProps = dispatch => ({
  handleLogin(email, password, role, history, redirectPath) {
    dispatch(login(email, password, role, history, redirectPath));
  },
  handleIframeLogin(obj){
    dispatch(getIframeLogin(obj))
  },
  handleGetSocialUrl(providerName, userRole, callbackPath, invitationCompanyId) {
    dispatch(getSocialUrl(providerName, userRole, callbackPath, { invitationCompanyId: invitationCompanyId }));
  },
  handleSocialLogin(code, role, provider, history, invitationCompanyId) {
    dispatch(socialLogin(code, role, provider, history, invitationCompanyId));
  },
  clearErrorMessages() {
    dispatch(clearLoginErrorMessages());
  },
  handleGetCompanyPublicInfo(companyId) {
    dispatch(getCompanyPublicInfo(companyId));
  },
  handleGetCompanyInfoByInviteId (inviteId){
    dispatch(getCompanyInfoByInvite(inviteId))
  },
  handleClearLogInMsg() {
    dispatch(clearLoginMessages())
  }
});

export const Unwrapped = SignUp;
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
